import '../css/app.scss';
import '../css/tailwind.css';
import '../css/sections/common.scss';
import '../css/sections/homepage.scss';
import '../css/sections/main.scss';
import '../css/sections/product.scss';
//import '../../MagentoTheme/styles/module/common.scss'
//import '../css/sections/_common.scss'

import '../../scripts/sections/imgsize.js';
import '../../scripts/sections/sticky-header.js';
import '../../scripts/sections/tabindex.js';
import '../../scripts/sections/resellers.js';
import '../../scripts/sections/faqs.js';
import '../../scripts/sections/footer.js';
import '../../scripts/sections/fotorama.js';
import '../../scripts/sections/product-tabs.js';