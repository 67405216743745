import $ from 'jquery';
function imgSize(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}
imgSize('#maincontent').then(elm => {
  let images = document.querySelectorAll("img");
  function getSizes() {
    images.forEach(image => {
      const height = image.offsetHeight;
      let y = image.getAttribute('height');
      if (!y) {
        image.setAttribute('height', height);
        let width = image.offsetWidth;
        let x = image.getAttribute('width');
        image.setAttribute('width', width);
      } else {}
    });
  }
  let timeoutID;
  function startTimeout() {
    timeoutID = setTimeout(() => {
      getSizes();
    }, 1000);
  }
  function Timeout() {
    clearTimeout(timeoutID);
  }
  startTimeout(Timeout);
});