import $ from 'jquery';
import domready from 'domready';
function waitForElm(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}
waitForElm('#ui-id-1').then(elm => {
  elm.removeAttribute('tabindex');
  let navigation = $(".navigation");
  let menusectioned = navigation.find(".level-top-sectioned");
  for (let i = 0; i < menusectioned.length; i++) {
    $(menusectioned[i]).attr("data-item-index", [i]);
    $(menusectioned[i]).attr("tabindex", "0");
    let indexValue = $(menusectioned[i]).attr('data-item-index');
    menusectioned[i].addEventListener('keydown', event => {
      if (event.key == 'Enter') {
        //console.log(indexValue);
        //menusectioned[i].querySelector('a').classList.add('helloworld');
        let href = menusectioned[i].querySelector('a').getAttribute('href');
        //console.log(href);
        window.location.href = href;
        if (href == 'javascript:void(0);') {
          for (const child of menusectioned[i].children) {
            child.style.removeProperty('display');
            child.style.position = 'relative';
            let childHref = child.querySelectorAll('.mm-no-children > a');
            if (childHref) {
              childHref.forEach(function (element) {
                element.setAttribute('tabindex', '0');
                element.addEventListener('keydown', event => {
                  if (event.key == 'Enter') {
                    let url = element.getAttribute('href');
                    window.location.href = url;
                  }
                });
              });
            }
          }
        }
      }
    });
  }
  let menufullwidth = navigation.find(".level-top-fullwidth");
  for (let i = 0; i < menufullwidth.length; i++) {
    $(menufullwidth[i]).attr("data-item-index", [i]);
    $(menufullwidth[i]).attr("tabindex", "0");
    let indexValue = $(menufullwidth[i]).attr('data-item-index');
    menufullwidth[i].addEventListener('keydown', event => {
      if (event.key == 'Enter') {
        let href = menufullwidth[i].querySelector('a').getAttribute('href');
        window.location.href = href;
        if (href == 'javascript:void(0);') {
          for (const child of menufullwidth[i].children) {
            if (child && child.matches('ul')) {
              child.style.removeProperty('display');
              child.style.display = 'block';
              let childHref = child.querySelectorAll('.mm-no-children > a');
              if (childHref) {
                childHref.forEach(function (element) {
                  element.setAttribute('tabindex', '0');
                  element.addEventListener('keydown', event => {
                    if (event.key == 'Enter') {
                      let url = element.getAttribute('href');
                      window.location.href = url;
                    }
                  });
                });
              }
            }
          }
        }
      }
    });
  }
});