var exists = document.querySelector(".reseller-list");
if (exists) {
  function addDiv() {
    document.querySelectorAll('.reseller-row').forEach(function (element) {
      const closest = element.querySelector('.title span');
      if (closest.innerText === 'Top Nutrition') {
        var disclaimer = document.createElement('span');
        disclaimer.classList.add('disclaimer');
        disclaimer.innerHTML = 'To ensure authenticity, look for the hologram on original Animal products and verify using the link provided <a style="text-decoration:underline;" href="https://animalpak.ru/proverka-podlinnosti-tovara" target="_blank">here</a>.';
        var placeholder = element.querySelector('.country span');
        placeholder.appendChild(disclaimer);
        disclaimer.setAttribute('style', 'display:block; font-size:12px;');
      } else {}
    });
  }
  window.addEventListener('load', addDiv);
  const observer = new MutationObserver(() => {
    addDiv();
  });
  observer.observe(document.querySelector(".reseller-list"), {
    subtree: false,
    childList: true
  });
}