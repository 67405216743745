let links = document.querySelectorAll('.footer-title');
links.forEach(function (link) {
  if (window.innerWidth < 768) {
    link.addEventListener('click', function () {
      link.classList.toggle('active');
      link.closest('section').querySelector('ul').classList.toggle('grid');
    });
    link.addEventListener('keypress', function () {
      link.classList.toggle('active');
      link.closest('section').querySelector('ul').classList.toggle('grid');
    });
  }
});