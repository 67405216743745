let titles = document.querySelectorAll('.data.item.title');
document.addEventListener("DOMContentLoaded", event => {
  if (titles) {
    function getTitle() {
      titles.forEach(title => {
        title.classList.remove('active');
      });
    }
    let timeoutID;
    function startTimeout() {
      timeoutID = setTimeout(() => {
        getTitle();
        setStyle();
      }, 1000);
    }
    function Timeout() {
      clearTimeout(timeoutID);
    }
    startTimeout(Timeout);
    function setStyle() {
      var style = document.createElement('style');
      style.innerHTML = `
        @media screen and (min-width: 1025px) {
          .data.item.title.active {
            background: #000;
            color: #FFF;
          }
        }
        `;
      document.head.appendChild(style);
    }
  }
});