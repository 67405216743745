function imgHeight(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}
imgHeight('.fotorama__img').then(elm => {
  let images = document.querySelectorAll(".fotorama__img");
  let arrows = document.querySelector('.fotorama__arr');
  function getSizes() {
    images.forEach(image => {
      if (image) {
        let h = image.offsetHeight + 'px';
        let herowrap = document.querySelector('.heroimage');
        if (herowrap) {
          let heroimg = herowrap.querySelector('img');
          let imgh = heroimg.offsetHeight + 'px';
          var herocss = document.createElement('style');
          herocss.innerHTML = `
          @media screen and (min-width: 769px) {
            .heroimage {
              min-height: ${imgh};
            }
          }
          `;
          document.head.appendChild(herocss);
        }
        var style = document.createElement('style');
        style.innerHTML = `
          @media screen and (min-width: 769px) {
            #html-body .fotorama__arr {
              max-height: ${h} !important;
              display: block !important;
            }
            #html-body .product.media {
              opacity: 1 !important;
            }
          }
          `;
        document.head.appendChild(style);
      } else {}
    });
  }
  let timeoutID;
  function startTimeout() {
    timeoutID = setTimeout(() => {
      getSizes();
    }, 300);
  }
  function Timeout() {
    clearTimeout(timeoutID);
  }
  startTimeout(Timeout);
  window.onresize = function () {
    getSizes();
  };
});