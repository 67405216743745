import domready from 'domready';
domready(() => {
  const header = document.querySelector('.page-header');
  const page = document.querySelector('#maincontent');
  const breadcrumbs = document.querySelector('.breadcrumbs');
  window.addEventListener('scroll', () => {
    if (window.scrollTop >= 1) {
      header.classList.add('fixed');
      header.classList.remove('sticky');
    } else {
      header.classList.remove('sticky');
      header.classList.add('fixed');
    }
    //page.style.marginTop = header.offsetHeight + 'px';
    if (breadcrumbs) {
      breadcrumbs.style.marginTop = header.offsetHeight + 'px';
    } else {
      page.style.marginTop = header.offsetHeight + 'px';
    }
  });
});