import $ from 'jquery';
function faqList(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}
faqList('#faqtab').then(elm => {
  let faqTab = document.querySelector('#faqtab');
  let faqlist = document.querySelector("#faq-list");
  let question = faqlist.querySelectorAll("h1, h2, h3, h4, h5, h6");
  let answer = faqlist.querySelectorAll("p");
  const parentElement = document.getElementById('faq-list');
  const children = Array.from(parentElement.children);
  function schemaMarkup() {
    for (let i = 0; i < children.length; i += 2) {
      const questionWrap = document.createElement('div');
      questionWrap.setAttribute('itemscope', '');
      questionWrap.setAttribute('itemprop', 'mainEntity');
      questionWrap.setAttribute('itemtype', 'https://schema.org/Question');
      if (children[i]) {
        questionWrap.appendChild(children[i]);
      }
      if (children[i + 1]) {
        questionWrap.appendChild(children[i + 1]);
      }
      parentElement.appendChild(questionWrap);
    }
    faqlist.setAttribute('itemscope', '');
    faqlist.setAttribute('itemtype', 'https://schema.org/FAQPage');
    for (let i = 0; i < question.length; i++) {
      //question[i].setAttribute('data-item-index', [i]);
      question[i].setAttribute('itemprop', 'name');
      question[i].addEventListener('click', event => {
        question[i].nextElementSibling.classList.toggle("active");
      });
    }
    const pTags = faqlist.querySelectorAll('p');
    pTags.forEach(pTag => {
      const div = document.createElement('div');
      div.classList.add('answer');
      div.setAttribute('itemscope', '');
      div.setAttribute('itemprop', 'acceptedAnswer');
      div.setAttribute('itemtype', 'https://schema.org/Answer');
      div.innerHTML = pTag.innerHTML;
      pTag.replaceWith(div);
    });
    document.querySelectorAll('.answer').forEach(paragraph => {
      const text = paragraph.textContent;
      paragraph.innerHTML = '';
      const div = document.createElement('div');
      div.setAttribute('itemprop', 'text');
      div.textContent = text;
      paragraph.appendChild(div);
    });
  }
  schemaMarkup();
});